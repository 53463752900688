<template>
  <div class="report-container">
    <div class="report-title-box">咨询/定制服务订单</div>
    <div class="divider"></div>
    <div class="report-content">
      <div v-if="total == 0" style="margin-top: 150px">
        <empty name="暂无数据"></empty>
      </div>
      <div class="report-box" v-show="total != 0">
        <el-table
          :data="tableData"
          style="width: 100%"
          header-cell-class-name="table_header"
          height="645px"
        >
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column
            prop="orderCode"
            label="订单编号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="commodityName"
            label="购买详情"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column prop="" label="支付金额（元）">
            <template slot-scope="scope">￥{{ calcNum(scope.row.orderAmount) }}</template>
          </el-table-column>
          <el-table-column prop="paymentDate" label="支付时间" width="180">
            <template slot-scope="scope">{{ scope.row.paymentDate || "-" }}</template>
          </el-table-column>
          <el-table-column prop="" label="支付状态">
            <template slot-scope="scope"
              ><span
                :class="
                  scope.row.paymentState == '60501001' ||
                  scope.row.paymentWay == '60591003'
                    ? 'payed'
                    : 'unpay'
                "
                @click="toPay(scope.row)"
                >{{
                  scope.row.paymentState == "60501001"
                    ? "已支付"
                    : scope.row.paymentState == "60501003"
                    ? "支付失败"
                    : scope.row.paymentState == "60501002" &&
                      scope.row.paymentWay == "60591003"
                    ? "待审核"
                    : scope.row.paymentState == "60501002"
                    ? "未支付"
                    : ""
                }}</span
              ></template
            >
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <pagination
          ref="pagination"
          :total="total"
          @change="paginChange"
          v-show="total != 0"
        >
        </pagination>
      </div>
    </div>
    <!-- <order-pay-modal ref="orderPayModal"></order-pay-modal> -->
    <!-- 支付弹窗 -->
		<pay-modal ref="payModal"></pay-modal>
  </div>
</template>

<script>
import OrderPayModal from "@/components/orderPayModal";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import PayModal from "@/components/payModal";
export default {
  components: {
    OrderPayModal,
    Pagination,
    Empty,
    PayModal,
  },
  data() {
    return {
      total: 0,
      tableData: [],
      params: {
        pageNum: 1,
        pageSize: 10,
      },
			noPay:0,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.consultPage();
    if(this.$route.query&&this.$route.query.noPay){
			this.noPay=this.$route.query.noPay*1
		}
  },
  methods: {
    // 获取列表
    consultPage() {
      this.$api.personalCenter
        .consultPage(this.params)
        .then((res) => {
          this.tableData = res.rows;
          this.total = res.total;
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 保留两位小数方法
    calcNum(val) {
      return Number(val).toFixed(2);
    },
    // 去支付
    handlePay(val) {
      console.log(val);
      if (val.paymentState == "60501001") {
        return;
      } else if (val.paymentWay == "60591003") {
        return;
      } else {
        let detailData = {};
        this.$refs.orderPayModal.open = true;
        detailData.commodityName = val.commodityName;
        detailData.orderAmount = val.orderAmount;
        detailData.orderCode = val.orderCode;
        this.$refs.orderPayModal.init(2, detailData);
      }
    },
    toPay(row) {
			console.log("row", row);
			this.$refs.payModal.orderData = {...row,price: row.orderAmount,info:row.commodityName,reportPay: true,isCustomOrder:true};
			this.$refs.payModal.open = true;
		},
    // 切换分页
    paginChange(page, pageSize) {
      this.params.pageNum = page;
      this.params.pageSize = pageSize;
      this.consultPage();
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
